import { FunctionComponent, memo } from "react";
import styles from "./Copyright.module.css";

const Copyright: FunctionComponent = memo(() => {
  return (
    <div className={styles.copyright}>© Creative Digital Consulting 2024</div>
  );
});

export default Copyright;
