import { FunctionComponent, memo, useEffect } from "react";
import Logo from "./Logo";
import Title from "./Title";
import Subtitle from "./Subtitle";
import Copyright from "./Copyright";
import styles from "./Body.module.css";

const Body: FunctionComponent = memo(() => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <main className={styles.body} id="main">
      <header className={styles.header} id="header" data-animate-on-scroll>
        <Logo />
      </header>
      <section className={styles.content} id="section" data-animate-on-scroll>
        <Title />
        <Subtitle />
      </section>
      <div className={styles.footer} data-animate-on-scroll>
        <Copyright />
      </div>
    </main>
  );
});

export default Body;
