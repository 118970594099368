import { FunctionComponent, memo } from "react";
import styles from "./Subtitle.module.css";

const Subtitle: FunctionComponent = memo(() => {
  return (
    <h1 className={styles.subtitle} id="subtitle">
      Coming soon
    </h1>
  );
});

export default Subtitle;
