import { FunctionComponent, memo } from "react";
import styles from "./Logo.module.css";

const Logo: FunctionComponent = memo(() => {
  return (
    <img
      className={styles.logoIcon}
      alt="Company logo"
      id="logo"
      src="/logo.svg"
    />
  );
});

export default Logo;
