import { FunctionComponent, memo } from "react";
import styles from "./Title.module.css";

const Title: FunctionComponent = memo(() => {
  return (
    <h1 className={styles.title} id="title">
      <p className={styles.creative}>CREATIVE</p>
      <p className={styles.creative}>DIGITAL</p>
    </h1>
  );
});

export default Title;
