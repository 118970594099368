import { FunctionComponent } from "react";
import Body from "../components/Body";
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
  return (
    <div className={styles.home}>
      <Body />
    </div>
  );
};

export default Home;
